/* eslint-disable import/prefer-default-export */
export const results = [ // 같이 먹기 좋은 음식, 피해야 할 음식
  {
    id: 0,
    food: 'coffee',
    mbti: 'ISTJ',
    sub: '열심히 저어라, 달고나 커피',
    des: '달고나 커피를 만드는 방법은 열심히 젓는 수 밖에 없어요.\n\n열심히 저어야만 완성되는 달고나 커피처럼, 원리원칙을 중시하는 정직한 삶을 사는 타입으로 보여요.\n\n냉정하고 이성적인 편이라 로봇같다는 평을 종종 듣기도 하지만, 내가 좋아하는 사람에게는 달고나처럼 달콤하기 그지 없는 타입이네요.\n\n또 한 번 시작한 일은 끝까지 해내기 때문에 책임감이 강하다는 평을 자주 듣기도 해요.\n\n물론 때로는 힘든 순간이 찾아오겠지만, 늘 그랬듯이 포기하지 마세요! 어느새 달콤한 달고나 커피가 완성될테니까요.',
    with: ['양지쌀국수', '갈비찜'], // [같이 먹기 좋은 음식, 피해야 할 음식]
    img: 'coffee-b26282659fb40df93f3e65ff5cd3f8cc.png',
    thumbnail: 'coffee_thumbnail-aad560660e72e4b13f8dc5f5bc6f4470.png',
    // https://mealion.kr/static/coffee-d19965fdfa08865aab4dbd598a370d34.png
    // https://mealion.kr/static/coffee_thumbnail-aad560660e72e4b13f8dc5f5bc6f4470.png
  },
  {
    id: 1,
    food: 'crowndaisy',
    mbti: 'ISFJ',
    sub: '대구탕 위 듬뿍 올린, 쑥갓',
    des: '성실하고 협조를 잘 하는 당신에게서 이타적이고 따뜻한 마음씨가 느껴져요. 당신은 대구탕 위 듬뿍 올린 쑥갓 같은 사람이에요.\n\n대구가 아니라 섭섭하다구요?\n대구탕의 주인공은 과연 대구일까요? 밀리언은 대구탕의 진정한 주인공이야 말로 쑥갓이라고 생각해요!\n\n협조적으로 대구탕의 맛을 끓어올리는 일등공신! 그러면서 동시에 은은하게 풍기는 쑥갓의 향기로 존재감도 각인 시키죠.\n\n당신 역시 온화하고 자비로운 마음으로 조직을 더 조화롭게 만드는 동시에 은근한 존재감을 풍기네요.',
    with: ['티라미수', '등심스테이크'],
    img: 'daegutang-275aed9d0d2c2aa5ee1ba3182a3a97db.png',
    thumbnail: 'daegutang_thumbnail-0f27c3b66853950b866ee167d1cc39ad.png',

  },
  {
    id: 2,
    food: 'songpyeon',
    mbti: 'INFJ',
    sub: '콩송편일까 꿀송편일까?, 송편',
    des: '언제나 통찰력을 가지고 예언자처럼 앞날을 예견하는 당신. 인내심이 많고 통찰력과 직관력이 뛰어난 편이에요.\n혹시 송편을 먹을 때도 통찰력을 발휘해 콩송편이 아니라 꿀송편만 쏙쏙 골라먹지 않나요?\n\n타인에 대한 배려심도 있어서 정신적인 리더\n역할도 해요. 특유의 다정함과 재치로 주변인들 모두가 당신을 좋아할 거에요.\n\n상상력을 자극하는 창작물을 좋아해 다양한\n분야의 콘텐츠를 두루 섭렵할 것 같네요.\n또 계획적인 것을 좋아하나 그렇다고 즉흥적인\n것을 싫어하지는 않는 사람이에요. 보수적이면서 동시에 반항적인 기질도 있고요.\n\n송편처럼 부드럽고 촉촉하지만 속에 들은게 콩일 때도 있고 꿀일 때도 있는 다양한 면을 가졌어요.',
    with: ['잡채', '감자'],
    img: 'songpyeon-f8be2c18a06b6765f3861aace0ca68fe.png',
    thumbnail: 'songpyeon_thumbnail-5dd4071dcb974e5a447d110013df56e4.png',
  },
  {
    id: 3,
    food: 'pizza',
    mbti: 'INTJ',
    sub: '치즈 크러스트를 추가한, 콤비네이션 피자',
    des: '부분을 조합해 비전을 제시하는 능력이 뛰어난 사람이네요.\n당신은 다양한 재료가 한 군데에 어우러져서 맛을 극대화하는 콤비네이션 피자 같은 사람이에요.\n\n게다가 추진력도 있고 고집도 있고 뒷심도 있어요. 그래서 일을 하면 조금 지칠지라도 한결 같이 굳은 의지와 인내로 끝장을 보고야 말죠.\n\n치즈크러스트를 추가해서 끝까지 맛있는 피자처럼, 끝 마무리까지 확실한 편이에요.\n먹다 보면 가끔 토핑이 도우에서 완전히 떨어져버리는 상황이 발생하는 피자처럼, 아니다 싶으면 단칼에 잘라내는 단호한 면도 있어요.\n\n하지만 본인의 마음에 드는 사람에게는 치즈처럼 한없이 부드러운 면이 있답니다.',
    with: ['과카몰리와 나초', '삼색나물'],
    img: 'pizza-813eaa7199b90cd117a2f6ead26dd7c0.png',
    thumbnail: 'pizza_thumbnail-ab348671ed84411eeca507c31ab7cdc7.png',
  },
  {
    id: 4,
    food: 'chickenstock',
    mbti: 'ISTP',
    sub: '어디서든 기지를 발휘하는, 치킨스톡',
    des: '당신의 상황적응력은 치킨스톡급!\n어디에 들어가도 맛을 좋게 만드는 치킨스톡이야 말로 논리적이고 뛰어난 상황적응력을 가진 당신과 찰떡입니다.\n\n치킨스톡은 메인 재료 만큼의 존재감은 아니지만, 밀리언처럼 맛을 좀 아는 자들은 그 중요성을 모를 수 없죠😎\n\n묵묵히 맡은 일을 열심히 하다보면 어느새 모두가 인정할거에요. \n\n다만 요리를 하며 간을 보다가 ‘어… 싱거운데..?’ 하면서 충동적으로 치킨스톡을 적정량보다 더 넣게 되면 짠맛이 너무 강해 실패할 수 있어요.\n때때로 충동적으로 행동했다가 실수할 수 있으니 조심해요!',
    with: ['갈비찜', '양지쌀국수'],
    img: 'chickenstock-d0e4b57c8581bbcc3a1e9eacd98ff07b.png',
    thumbnail: 'chickenstock_thumbnail-8ad11793c898f084b0a1e6c2a222d311.png',
  },
  {
    id: 5,
    food: 'sullungtang',
    mbti: 'ISFP',
    sub: '영혼까지 따뜻하게, 설렁탕', // 뎁혀주곤 -> 데워주곤
    des: '따뜻한 마음을 가진 당신은 설렁탕 같은 사람입니다.\n\n추운 날, 설렁탕을 한 그릇 먹으면 영혼까지 따뜻해지는 기분이 들어요. 타인에게도 특유의 따뜻함으로 마음을 뎁혀주곤 하는군요.\n\n당신은 설렁탕의 뽀얀 국물처럼 속마음을 잘 보여주지 않기도 해요.\n하지만 당신의 마음을 얻는다면 푸짐한 고기와 소면까지 먹을 수 있는 설렁탕처럼, 당신에게 있는 것들을 아낌없이 주는 나무가 됩니다.\n\n또 파, 양념장, 다진마늘 등 다양한 재료로 본인만의 커스텀이 가능한 설렁탕처럼 다채롭고 자유로운 사람이에요.',
    with: ['등심스테이크', '티라미수'],
    img: 'soup-fdc55a0b440daaa989dcb851d4032648.png',
    thumbnail: 'soup_thumbnail-1d0e81f73a55ad8e613f53722a8c1677.png',
  },
  {
    id: 6,
    food: 'bibimmyeon',
    mbti: 'INFP',
    sub: '이상적인 조화, 삼겹살을 곁들인\n비빔면',
    des: '당신은 삼겹살을 곁들인 비빔면의 이상적인 조화처럼 이상적인 가치관을 가진 사람이에요.\n때때로 이상적인 가치관을 이해받지 못하는 경우도 있어요.\n\n남을 지배하려는 성향보다는 함께하려는 성향이 강해요. 그래서 그냥 비빔면이 아니라 ‘함께’의 가치를 아는, 삼겹살을 올린 비빔면인거죠!\n다만, 주변의 이야기를 많이 듣다보니 실행이 늦을 수가 있어요.\n\n그러니 명심하세요! 비빔면의 생명은 탱탱한 면발인만큼 타이밍이 중요해요. 주변의 이야기를 듣는것도 중요하지만, 때로는 본인만의 주관으로 빠른 결단도 필요해요!',
    with: ['감자', '잡채'],
    img: 'ramyeon-01ff2149e4701f84d549bb267b911684.png',
    thumbnail: 'ramyeon_thumbnail-fab9b2ea000c164578f0d9c9534c2a22.png',
  },
  {
    id: 7,
    food: 'skewers',
    mbti: 'INTP',
    sub: '맛있지만 조심히 먹어!, 산적꼬치',
    des: '산적꼬치야 말로 아이디어 뱅크이자 전략적이고 냉철한 당신과 일치하는 음식이에요.\n\n다양한 재료를 꼬치에 꽂아 계란물을 묻혀 구울 생각을 하다니… 엄청난 아이디어는 물론 맛을 향한 뛰어난 전략이에요.\n\n다만 꼬치에 질리지 않게 조심히 먹어야하는 만큼, 비평적 관점을 가지고 하는 냉철한 말들이 때로는 남들을 쿡… 찌를 수 있으니 조심하세요!\n\n또 호불호가 분명해 마음에 드는 게 있으면 꼬치에 꽂힌 산적처럼 확 꽂혀버리는 타입이에요. \n\n하지만 먹다보면 종종 꼬치를 탈출해버리는 산적처럼, 때때로 마무리가 안된 채로 일을 끝내기도 해요. 마지막까지 화이팅하기로 해요!',
    with: ['삼색나물', '과카몰리와 나초'],
    img: 'meatggochi-3cc211e8bc1ff38b65461d838fae85d2.png',
    thumbnail: 'meatggochi_thumbnail-41fa2e87bb75b11e2413e21e09808d25.png',
  },
  {
    id: 8,
    food: 'japchae',
    mbti: 'ESTP',
    sub: '재료가 많을수록 맛있는, 잡채',
    des: '당신은 다양한 분야에서 활동하는 사교적인 타입이에요.\n\n재료가 많을 수록 맛있는 잡채처럼 당신도 다양한 친구, 취미생활 등 다양한 분야에서 활동적인 생활을 할 수록 빛나는 사람이에요.\n앞으로도 열정을 잃지 말고 다양한 분야에서 능력을 발휘하세요.\n\n예측불가한 사건이나 도전을 좋아해서, 때로는 잡채에 아보카도나 파인애플 같이 뜬금없는 재료를 넣을 타입이에요.\n\n주변사람이 ‘잡채에 이게 뭐야~’라고 해도 쾌활하게 웃어 넘길 타입이죠.\n대표적인 잔치음식인 잡채처럼 타고난 인싸기질을 가졌어요.',
    with: ['송편', '비빔면'],
    img: 'japchae-a953179ed46232c6e2199ff973435251.png',
    thumbnail: 'japchae_thumbnail-1cec9d233af146cee76742fe90177467.png',
  },
  {
    id: 9,
    food: 'nacho',
    mbti: 'ESFP',
    sub: '파티에 찰떡궁합, 과카몰리와 나초',
    des: '사교적이고 타인의 감정을 잘 살피는 당신, 분위기를 긍정적으로 고조시킬 줄 아는 당신은 파티에 찰떡궁합, 과카몰리와 나초같은 사람이에요.\n\n나초의 바삭함처럼 경쾌한 면과 과카몰리의 촉촉함처럼 부드러움을 동시에 가진 당신은 그야말로 파티의 주인공이에요.\n\n다만 종종 일을 미루곤 후회할 수 있어요.\n그러니 기억하세요. 과카몰리는 아보카도로 만들어서 순식간에 색이 변한다는 것!\n\n미루지 않고 실행한다면 신선한 아보카토로 만든 과카몰리를 얹은 나초처럼 최고의 결과를 얻을 수 있을거에요.',
    with: ['콤비네이션 피자', '산적꼬치'],
    img: 'nacho-bbf4e133d4d9e138e50ff61bc9c67218.png',
    thumbnail: 'nacho_thumbnail-cb038117ba5bf443180ce20086a1e2b4.png',
  },
  {
    id: 10,
    food: 'noodle',
    mbti: 'ENFP',
    sub: '스리라차를 듬뿍 뿌린, 양지쌀국수',
    des: '양지쌀국수는 담백하고 개운한 맛으로 모두에게 큰 호불호 없는 음식이에요.\n하지만 당신은 그냥 양지쌀국수가 아닌 스리라차를 듬뿍 뿌린 양지쌀국수!\n\n스리라차의 매콤한 맛처럼, 스파크가 튀듯 번뜩이는 아이디어를 제공하기도 하고 모두에게 호불호 없는듯 부드럽게 다가갔다가도, 상처를 받으면 본인의 진심을 감춰버리기도 해요.\n스리라차를 듬뿍 뿌려서 본연의 맛을 지운 양지쌀국수처럼요!\n\n그래도 부드러운 면발까지는 감출 수 없어요.\n때로는 스리라차를 뿌린 겉모습만 보고 당신을 쉽게 평가하는 사람들이 있을지라도, 시간이 흐르면 촉촉하고 부드러운 마음씨를 모두가 알아줄거에요.',
    with: ['달고나 커피', '치킨스톡'],
    img: 'ssalnoodle-0779b037ad3ab88f4925acce9ee3a350.png',
    thumbnail: 'ssalnoodle_thumbnail-462e6917026dc137d33c35c261334a5d.png',

  },
  {
    id: 11,
    food: 'tiramisu',
    mbti: 'ENTP',
    sub: '나를 끌어올리는 맛, 티라미수',
    des: '‘나를 끌어올리다’라는 뜻을 가진 티라미수는 이탈리아의 한 레스토랑에서 개발됐다고 해요.\n\n새롭게 만들어져 많은 사람들에게 사랑받는 티라미수는 새로움을 즐기는 당신같은 음식이에요.\n\n논리에 어긋나거나 강제성을 띄는 일에는 저항력도 큰 타입이네요.\n급하게 먹다가 기침를 하면 코코아 가루가 뿜어져 나오는 티라미수처럼, 당신을 말도 안되는 이유로 속박한다면 카운터펀치를 날리고 탈출할 사람이에요.\n\n달콤하되 커피의 씁쓸한 듯 깊은 맛도 느껴지는 티라미수는 즐겁고 단순한듯 보이지만 동시에 심오한 당신같은 음식이에요.',
    with: ['쑥갓', '설렁탕'],
    img: 'tiramisu-d0760523c293d59c54d9e6ca98e3db0d.png',
    thumbnail: 'tiramisu_thumbnail-0fa0418f6ca4efa30a9c573b0a59bd3a.png',
  },
  {
    id: 12,
    food: 'potato',
    mbti: 'ESTJ',
    sub: '여기저기 잘 어울리는, 감자',
    des: '‘감자?! 감자는 재료아니야?’ 실망하지 마세요.\n\n감자야말로 무궁무진한 변신이 가능함은 물론 여기저기 잘 어울리는 팔방미인이니까요.\n\n감자전, 감자볶음같은 한식부터 감자튀김, 감자칩, 매쉬드 포테이토 같은 양식은 물론, 그냥 찌기만 해도 맛있는 감자는 현실적으로 일을 많이 하는 주방 위의 만능 열쇠입니다.\n\n항상 실용적이고 진취적으로 일을하는 사업가 같은 당신은 그야말로 감자같은 사람이에요.\n\n모두와 잘 어울리는 사교성 좋은 면도 똑 닮았네요.',
    with: ['비빔면', '송편'],
    img: 'potato-d238f8439c17ab66a5f30262192cedec.png',
    thumbnail: 'potato_thumbnail-d5140a98b86c120aee7c1a52e304648a.png',
  },
  {
    id: 13,
    food: 'herb',
    mbti: 'ESFJ',
    sub: '은은하게 빛나는, 삼색나물',
    des: '은은하게 빛나는 삼색나물은 항상 친절과 현실감을 바탕으로 타인을 배려하는 당신같은 음식이에요.\n\n자극적이지 않아 언제나 속이 편하고, 다른 재료와 어우러져 비빔밥이 되어 새로운 면모를 보여주기도 하는 삼색나물!\n\n눈을 사로잡는 화려한 비주얼이나 향을 가지진 않았지만, 존재만으로도 타인에게 안정감을 느끼게 해주는 당신과 일치하는 음식이에요.\n\n다만 귀가 얇아 종종 주관이 흔들릴 수 있어요.\n하지만 기억하세요. 삼색나물은 강한 조미료의 맛을 빌리는 게 아닌 나물 자체로 맛을 내는 음식이라는 점! 남들의 말에 흔들리지 말고 자신만의 길을 걸어가세요.',
    with: ['산적꼬치', '콤비네이션 피자'],
    img: 'namul-db02819b47ea870b1d223890bf892068.png',
    thumbnail: 'namul_thumbnail-db576b4e9b904cf4e314c607b52157df.png',
  },
  {
    id: 14,
    food: 'galbijjim',
    mbti: 'ENFJ',
    sub: '푹 끓일 수록 맛있는, 갈비찜',
    des: '갈비찜은 만들기 위해 오랜 시간이 걸려요.\n하지만 다 만들고 나면 그 맛이 일품이죠!\n\n능숙한 언변을 바탕으로 타인의 성장을 도모하고 협동할 줄 아는 당신.\n인내를 가지고 맛있는 요리를 위해 다양한 재료를 한군데 모아 푹~ 끓이듯, 하고 싶은 일이 있다면 타고난 언변으로 사람들을 모아 시간을 두고 단계적으로 실행하면 못할 일이 없을 거에요!\n\n다만 가끔 예상치 못하게 갈비찜을 끓이는 뚝배기가 넘칠 때도 있어요.\n인생도 마찬가지에요. 계획도 좋지만 인생은 항상 계획대로 되진 않아요.\n지나친 계획에 얽매이기 보다는 융통성을 발휘해서 예상치 못한 순간에 불을 줄일 줄도 알아야해요.',
    with: ['치킨스톡', '달고나 커피'],
    img: 'galbi-b45250ced01501443c96206ff0c4e0fc.png',
    thumbnail: 'galbi_thumbnail-6bab27405683a514da5dd8095e3ccb3a.png',
  },
  {
    id: 15,
    food: 'steak',
    mbti: 'ENTJ',
    sub: '메인디쉬, 등심스테이크',
    des: '언제나 모임의 메인디쉬같은 당신.\n사람들을 이끄는 그룹의 리더 역할이 잘 어울려요.\n\n메인디쉬처럼 사람을 사로 잡는 다양한 매력과 타고난 능력을 가졌네요.\n\n하지만 잊지 마세요. 당신이 빛날 수 있는 이유는 맛있는 등심 스테이크처럼 당신의 능력 때문도 있지만, 당신 주변 사이드디쉬의 역할도 한 몫 한다는 것!\n\n지도자와 독재자는 종이 한 장 차이에요.\n항상 겸손함을 잃지 말고 주변을 살피도록 해요. 주변을 살피며 조화를 생각한다면 완벽한 메인디쉬가 될 수 있을거에요.',
    with: ['설렁탕', '쑥갓'],
    img: 'steak-bfede8ea0319d5aaebba87dedef44b06.png',
    thumbnail: 'steak_thumbnail-871440720efa2113c209605b0c4cac3a.png',
  },
];
